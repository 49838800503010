import React from 'react'
import PropTypes from "prop-types"
import ReactMarkdown from "react-markdown"

import CTAButton from "./CTAButton"

const SimpleHeader = (props) => {
    const {
      heading,
      header,
      subheader,
      buttonLink,
      buttonText,
      noIcon = false,
      isPageHeader = false,
      columnSize = "",
      subheaderSize = 'is-size-5',
      playIcon = false
    } = props
    return (
        <>
            <div className={`column ${columnSize}`}>
                <div className="block">
                    {isPageHeader
                        ? (
                          <>
                            <h4 className="is-h4">{ heading }</h4>
                            <h1 className="title is-h1">{header}</h1>
                          </>
                      )
                        : (<h2 className="title is-2 has-text-weight-bold">{ header }</h2>)
                    }
                </div>
                <div className="block">
                    <ReactMarkdown className={`subtitle is-size-4`}>{ subheader }</ReactMarkdown>
                </div>
                <CTAButton link={buttonLink} text={buttonText} noIcon={noIcon} playIcon={playIcon} />
            </div>
        </>
    )
}

SimpleHeader.propTypes = {
    heading: PropTypes.string,
    header: PropTypes.string,
    subheader: PropTypes.string,
    buttonLink: PropTypes.string,
    buttonText: PropTypes.string,
    noIcon: PropTypes.bool,
    isPageHeader: PropTypes.bool,
    columnSize: PropTypes.string,
    subheaderSize: PropTypes.string
}

export default SimpleHeader